// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-content-page-js": () => import("/opt/build/repo/src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-js": () => import("/opt/build/repo/src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-amenities-js": () => import("/opt/build/repo/src/pages/local-amenities.js" /* webpackChunkName: "component---src-pages-local-amenities-js" */),
  "component---src-pages-media-js": () => import("/opt/build/repo/src/pages/media.js" /* webpackChunkName: "component---src-pages-media-js" */),
  "component---src-pages-testimonials-js": () => import("/opt/build/repo/src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */)
}

